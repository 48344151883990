<template>
    <Layout :footer-style="2">
        <!-- Start Timeline Area -->
        <div class="rwt-timeline-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="谭渝洁9月工作报告及10月计划"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <Timeline :timeline-data="timelineData"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Timeline Area -->

        <Separator/>

        <!-- Start progress style-gradient -->
        <!-- <div class="rwt-progressbar-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1">
                        <SectionTitle
                            text-align="center"
                            subtitle="6月计划完成度"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <Progressbar :progress-data="progressOneData" :progress-style="3"/>
            </div>
        </div> -->
        <!-- End progress style-gradient -->

        <!-- <Separator/> -->

        <!-- Start progress style-gradient -->
        <div class="rwt-progressbar-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1">
                        <SectionTitle
                            text-align="center"
                            subtitle="10月计划"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-10 offset-lg-1" style="text-align: center;">
                        <p>1、配合静姐进行商城下一步的计划探讨和实施：商家入驻、如何引流？</p>
                        <p>2、每日分账相关的工作</p>
                        <p>3、产品名修改完成后，探讨如何系统化管理点位、一键生成账单等功能</p>
                        <p>4、管家数据量增加引起的响应慢，做优化处理。</p>
                        <p>5、其他管家使用优化、其他需求功能</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- End progress style-gradient -->

        <Separator/>
    </Layout>
</template>
<script>
    import Layout from '../components/common/Layout'
    import Icon from '../components/icon/Icon'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Timeline from "../components/elements/timeline/Timeline";
    import Progressbar from "../components/elements/progressbar/Progressbar";
    

    export default {
        name: 'Demo',
        
        components: {Icon, Layout, Separator, SectionTitle,Timeline,Progressbar},
        data() {
            return {
                timelineData: [
                    {
                        id: '1',
                        date: 'Step-1',
                        title: '智医管家',
                        image: 'yzt.png',
                        // button: '智医管家 >>',
                        // url: 'https://inside.tongjizhiyi.com/store',
                        workingStep: [
                            {
                                stepDescription: '智医管家完成中心正式服功能测试并上线使用、并每日配合做清分处理',
                                button: '分账系统',
                                url: 'https://inside.tongjizhiyi.com/store/#/zhongxin/files'
                            },
                            {
                                stepDescription: '分账系统增加预约自动分账（今日导入清分表、第二天凌晨自动完成提交清分文件到中信、中信清分完成后自动给用户提现）、清分明细（清分文件的交易明细）、月账单记录和导出（用于财务月底对账）',
                            },
                            {
                                stepDescription: '智医管家预测数据版本大屏、移动大屏、管家APP调整，配合接待需要对订单数量、金额做调整',
                                button: '管家',
                                url: 'https://inside.tongjizhiyi.com/h5test/#/pages/expand/index'
                            },
                            {
                                stepDescription: '和兴元技术人员完成退款订单的数据同步接口对接，并商议好凌晨2点推送前一天的退款订单。',
                            },
                            {
                                stepDescription: '编写智医管家的项目实施过程书，并编写过程书中需要的附件项目策划书',
                                button: '项目实施过程书',
                                url: 'https://inside.tongjizhiyi.com/uploads/work/智医管家项目实施过程.pdf'
                            },
                            {
                                stepDescription: '管家APP优化：首页各局销售可查看某天销售额、医院的销售分析、接入大屏、官网，智医管家和智慧管家做登陆兼容',
                            },
                        ]
                    },
                    {
                        id: '2',
                        date: 'Step-2',
                        title: '通济智医商城',
                        image: 'yishi.png',
                        workingStep: [
                            {
                                stepDescription: '编写通济智医商城的项目实施过程书，并编写过程书中需要的附件项目策划书',
                                button: '项目实施过程书',
                                url: 'https://inside.tongjizhiyi.com/uploads/work/通济智医商城项目实施过程.pdf'
                            },
                            {
                                stepDescription: '编写通济智医商家使用指南，包含商家的入驻流程、上线流程、客服使用流程、分账流程、营销功能使用和其他商城活动',
                                button: '使用指南',
                                url: 'https://inside.tongjizhiyi.com/uploads/work/通济智医商家使用指南.pdf'
                            },
                            {
                                stepDescription: '小程序商城按小程序政策，在9月15日以后必须强制提示用户同意隐私指引政策，才允许用户使用小程序。目前已完成微信4个指引接口对接。',
                            },
                            {
                                stepDescription: '商城按小程序要求申请开通经纬度获取权限，APP按要求注册腾讯地图企业账号并做APP定位的版本兼容，并相应支付宝政策，需要授权时必须提示用户因为什么需要授权，并取消首次进入APP授权功能',
                            },
                            {
                                stepDescription: '查阅京东健康公众号下的京东买药、京喜健康APP、叮当快药APP、新氧医美APP，总结如下：1、商城内除了有论坛互动以外，都有一个医师/药师免费在线咨询的功能，在购药前都可以先咨询后购买防止购错；2、大量的优惠券、新人优惠券、活动优惠券促进销售；3、除了叮当快药入驻了同城店铺以外，其他的均以快递方式送货，所以如果我们完成线上购物，骑手在就近的自助药房取货送到家，将是一大亮点；',
                            },
                        ]
                    },
                    // {
                    //     id: '3',
                    //     date: 'Step-2',
                    //     title: '其他完成工作及展示',
                    //     image: 'guanwang.png',
                    //     // button: '移动大屏',
                    //     // url: 'https://inside.tongjizhiyi.com/digdata4/',
                    //     workingStep: [
                    //         {
                    //             stepDescription: '移动端官网制作并美化',
                    //             button: '移动官网',
                    //             url: 'https://inside.tongjizhiyi.com/website'
                    //         },
                    //         {
                    //             stepDescription: '制作新的讲解版大屏',
                    //             button: '讲解版大屏',
                    //             url: 'https://inside.tongjizhiyi.com/digdata10000'
                    //         },
                    //     ]
                    // },
                ],
                progressOneData: [
                    {
                        id: 1,
                        title: '中信分账系统实现应用',
                        percentage: 50,
                        progressClass: 'bar-color-1',
                    },
                    {
                        id: 2,
                        title: '制作软件开发项目实施记录',
                        percentage: 50,
                        progressClass: 'bar-color-2',
                    },
                    {
                        id: 3,
                        title: '维护管家现有版本：智医管家、智慧管家、智医管家医院版',
                        percentage: 0,
                        progressClass: 'bar-color-3',
                    },
                    {
                        id: 4,
                        title: "维护商城、跟进EDI进度、上架进度",
                        percentage: 90,
                        progressClass: 'bar-color-4',
                    },
                ],
            }
        },
        created() {
        },
}
</script>
<style lang="scss" scoped>

</style>