<template>
    <!-- <Layout header-class="header-transparent" :show-newsletter="false" :show-cta="false"> -->
    <Layout>
        <div class="">
            <div class="">
                <!-- <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="部分药房实景"
                            data-aos="fade-up"
                        />
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-lg-12 mt--10">
                        <Gallery10 :gallery-data="robotData" column="col-lg-12 col-md-6 col-sm-6 col-12"/>
                    </div>
                </div>
            </div>
        </div>
        <CoolLightBox
            :items="galleryData"
            :index="imageIndex"
            @close="imageIndex = null">
        </CoolLightBox>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Gallery10 from "../components/elements/gallery/Gallery10"

    export default {
        name: 'About',
        components: { SectionTitle, Separator, Layout, Gallery10},
        data() {
            return {
                robotData: [
                    {
                        id: 1,
                        src: require('@/assets/images/heyong/H0000.png'),
                        thumb: require('@/assets/images/heyong/H0000.png'),
                        type: "image",
                    },
                    {
                        id: 2,
                        src: require('@/assets/images/heyong/H0002.png'),
                        thumb: require('@/assets/images/heyong/H0002.png'),
                        type: "image",
                    },
                    {
                        id: 3,
                        src: require('@/assets/images/heyong/H0005.png'),
                        thumb: require('@/assets/images/heyong/H0005.png'),
                        type: "image",
                    },
                    {
                        id: 4,
                        src: require('@/assets/images/heyong/H0006.png'),
                        thumb: require('@/assets/images/heyong/H0006.png'),
                        type: "image",
                    },
                ]
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {
            }
        }
    }
</script>
<style lang="scss" scoped>
@keyframes wrapper-gradient {
    100% {
    transform: translateY(0);
    }
    0% {
    transform: translateY(100%);
    }
}
.bg_image {
    animation: wrapper-gradient 0.5s linear;
}
</style>