<template>
    <Layout>
        <div class="slider-area slider-style-1 height-850 bg_image"
             data-black-overlay="2"
             >
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <!-- <div class="inner pt--80 pb--80 text-center" style="color:#fff;font-size: 25px;" v-html="title"></div> -->
                    </div>
                </div>
            </div>
            <div class="button-group" data-aos-delay="60">
                <Button title="欢迎咨询 >>" url="./contact" size="small"/>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Portfolio from '../components/elements/portfolio/Portfolio'
    import PortfolioItemMixin from '../mixins/PortfolioItemMixin'
    import AboutFour from '../components/elements/about/AboutFour'
    import ServiceFour from '../components/elements/service/ServiceFour'
    import axios from 'axios'
    import Gallery from "../components/elements/gallery/Gallery"
    import Button from "../components/elements/button/Button";

    export default {
        name: 'PortfolioPage',
        components: {Portfolio, SectionTitle, Layout, Breadcrumb, AboutFour, ServiceFour,Separator,Gallery,Button},
        mixins: [PortfolioItemMixin],
        data() {
            return {
                list: [],
                title: '',
                timer: null,
                titles: '著名华人成功学专家陈安之说过：“选对行业赚大钱”Z应该选择什么样的行业呢？Z我们认为，是趋势行业。Z什么是趋势行业？Z现在没有或很少，但未来一定会发展的很多很普遍的行业。Z趋势行业的特点是什么？Z趋势行业的表现为： Z容量大：市场潜在需求量大；Z趋势好：越来越多的人在接受该产品；Z竞争少：目前竞争程度不高，处于行业的初期阶段。Z当您发现某个行业还没人进入或者大家都很排斥的时候，并且您相信未来会很有发展，您就不要再犹豫……Z通济智医.智慧无人药房项目，智慧医疗新风口Z未来已来-合作共赢',
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {
                var lengths = this.titles.length
                var i = 0
                var str = ''
                this.timer = setInterval(() => {
                    str = this.titles.slice(i,i+1);
                    if(str=='Z'){
                        str = '<br/>'
                    }
                    this.title = this.title + '' + str;
                    i++
                    if(i>lengths){
                        clearInterval(this.timer)
                    }
                }, 100);
                setTimeout(() => {
                    this.title = this.title + '' + this.titles.slice(i,i+1);
                    i++
                }, 50);
            }

        }
    }
</script>
<style scoped>
@keyframes wrapper-gradient {
        100% {
            transform: translateY(0);
        }
        0% {
            transform: translateY(100%);
        }
    }
    .bg_image {
      animation: wrapper-gradient 0.5s linear;
    }
    .button-group{
        position: absolute;
        width: 100%;
        bottom:10px;
        text-align: center;
        margin: 0px auto;
    }
</style>