<template>
    <div v-if="timelineStyle === 1" class="rn-timeline-wrapper timeline-style-one position-relative">
        <div class="timeline-line"/>

        <div class="single-timeline mb--30"
             v-for="(timeline, index) in timelineData"
             :key="index">
            <div class="timeline-dot">
                <div class="time-line-circle"/>
            </div>
            <div class="single-content">
                <div class="inner">
                    <div class="row row--30 align-items-center" v-if="timeline.image">
                        <div class="order-2 order-lg-1 col-lg-7 mt_md--40 mt_sm--40">
                            <div class="content">
                                <span class="date-of-timeline" data-aos="fade-up" data-aos-delay="50">
                                    {{ timeline.date }}
                                </span>
                                <h3 class="title" data-aos="fade-up" data-aos-delay="80">
                                    {{ timeline.title }}
                                </h3>
                                <p class="description" data-aos="fade-up" data-aos-delay="110">
                                    {{ timeline.description }}
                                </p>

                                <div class="row row--30">
                                    <div class="col-lg-12 col-md-12 col-12"
                                         v-for="(step, index) in timeline.workingStep"
                                         :key="index"
                                         data-aos="fade-up"
                                         data-aos-delay="140">
                                        <div class="working-list">
                                            <p data-aos="fade-up" data-aos-delay="60">
                                                {{ (index+1) + '、' + step.stepDescription }}
                                                <a v-if="step.button" class="btn-default btn-small round"
                                                    :href="step.url"
                                                    data-aos="fade-up"
                                                    data-aos-delay="10"
                                                    target="_blank">
                                                        <span>{{ step.button }}</span>
                                                    </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="timeline.button" class="read-morebtn mt--20">
                                    <a class="btn-default btn-large round"
                                       :href="timeline.url"
                                       data-aos="fade-up"
                                       data-aos-delay="170"
                                       target="_blank">
                                        <span>{{ timeline.button }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="order-1 order-lg-2 col-lg-5">
                            <div class="thumbnail" data-aos="fade-up">
                                <img class="w-100"
                                     style="cursor:pointer;"
                                     :src="require(`@/assets/images/my/${timeline.image}`)"
                                     @click="open_img(require(`@/assets/images/my/${timeline.image}`))"
                                     alt="Corporate Vue Template"/>
                            </div>
                        </div>
                    </div>
                    <div class="row row--12 align-items-center" v-if="!timeline.image && timeline.image2">
                        <div class="order-2 order-lg-1 col-lg-3 mt_md--40 mt_sm--40">
                            <div class="content">
                                <span class="date-of-timeline" data-aos="fade-up" data-aos-delay="50">
                                    {{ timeline.date }}
                                </span>
                                <h3 class="title" data-aos="fade-up" data-aos-delay="80">
                                    {{ timeline.title }}
                                </h3>
                                <!-- <p class="description" data-aos="fade-up" data-aos-delay="110">
                                    {{ timeline.description }}
                                </p> -->
                                <div class="row row--30">
                                    <div class="col-lg-12 col-md-12 col-12"
                                         v-for="(step, index) in timeline.workingStep"
                                         :key="index"
                                         data-aos="fade-up"
                                         data-aos-delay="140">
                                        <div class="working-list">
                                            <p data-aos="fade-up" data-aos-delay="60">
                                                {{ (index+1) + '、' + step.stepDescription }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="order-1 order-lg-2 col-lg-3" v-for="item in timeline.image2">
                            <div class="thumbnail" data-aos="fade-up">
                                <img class="w-100" style="cursor:pointer;"
                                     :src="require(`@/assets/images/tanyujie/${item}`)"
                                     @click="open_img(require(`@/assets/images/tanyujie/${item}`))"
                                     alt="Corporate Vue Template"/>
                            </div>
                        </div>
                        <div class="order-1 order-lg-2 col-lg-3" v-if="timeline.video">
                            <div class="thumbnail" data-aos="fade-up">
                                <Video :video-data="timeline.video" column="col-12"/>
                                <!-- <video class="w-100" v-if="timeline.video" :src="require(`@/assets/images/tanyujie/${timeline.video}`)"></video> -->
                            </div>
                        </div>
                        <div class="order-1 order-lg-2 col-lg-3 mt--10" v-for="item in timeline.image3">
                            <div class="thumbnail" data-aos="fade-up">
                                <img class="w-100" style="cursor:pointer;"
                                     :src="require(`@/assets/images/tanyujie/${item}`)"
                                     @click="open_img(require(`@/assets/images/tanyujie/${item}`))"
                                     alt="Corporate Vue Template"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CoolLightBox
            :items="galleryData"
            :index="imageIndex"
            @close="imageIndex = null">
        </CoolLightBox>
    </div>

    <div v-else-if="timelineStyle === 2 || timelineStyle === 3 || timelineStyle === 4" 
         class="timeline-style-two bg-color-blackest">
        <div class="row row--0">
            <div :class="`col-lg-3 col-md-3 rn-timeline-single ${checkTimelineStyle}`"
                 v-for="(timeline, index) in timelineData"
                 :key="index">
                <div class="rn-timeline">
                    <p class="title title_new" data-aos="fade-up" data-aos-delay="60">
                        {{ timeline.title }}
                    </p>
                    <div class="progress-line">
                        <div class="line-inner" style="background-color:var(--color-zhutise10);"/>
                    </div>
                    <div class="progress-dot">
                        <div class="dot-level" style="background-color:var(--color-zhutise10);">
                            <div class="dot-inner" style="background-color:#F5F8FA;"/>
                        </div>
                    </div>
                    <p class="description" data-aos="fade-up" data-aos-delay="60">
                        {{ timeline.description }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
    import Video from "../video/Video"
    export default {
        name: 'Timeline',
        components: {Video},
        props: {
            galleryData: [],
            imageIndex: null,
            timelineData: {},
            timelineStyle: {
                type: Number,
                default: 1
            }
        },
        computed: {
            checkTimelineStyle() {
                if (this.timelineStyle === 3) return 'no-gradient';
                else if (this.timelineStyle === 4) return 'dark-line';
                else return '';
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {

            },
            open_img(url){
                this.galleryData = [
                    {
                        id: 1,
                        thumb: url,
                        src: url,
                        type: 'image',
                    }
                ]
                this.imageIndex = 0
            }
        }
    }
</script>