<template>
    
</template>

<script>
    import Icon from '../../icon/Icon'
    import MobileMenu from './MobileMenu'
    import AppFunctions from '../../../helpers/AppFunctions'
    import Nav from './Nav'
    import Logo from '../../elements/logo/Logo'
    import axios from 'axios'

    export default {
        name: 'Header',
        props: {
            data: {
                default: null,
                
            }
        },
        components: {Logo, Nav, MobileMenu, Icon},
        data() {
            return {
                AppFunctions,
                imageIndex: null,
                header_data: {},
                galleryData: [],
            }
        },
        methods: {
            init() {
                axios.get(`https://inside.tongjizhiyi.com/index.php?s=/api/passport/get_header`).then(
					response => {
                        if(response.data.status==200){
                            this.header_data = response.data.data.list
                            this.galleryData = response.data.data.list.gzh_img_list
                        }else{
                            this.result = response.data.message
                        }
					},
					error => {
						//请求后更新List的数据
					}
				)
            },
            toggleStickyHeader() {
                const scrolled = document.documentElement.scrollTop;
                if (scrolled > 100) {
                    AppFunctions.addClass('.header-default', 'sticky');
                } else if (scrolled <= 100) {
                    AppFunctions.removeClass('.header-default', 'sticky');
                }
            }
        },
        created() {
            window.addEventListener('scroll', this.toggleStickyHeader);
            this.init()
            // const nowDate = new Date();
            // let hours = nowDate.getHours();
            // if(hours<20 && hours>=7){
            //     AppFunctions.toggleClass('body', 'active-light-mode')
            // }
            // console.log(hours)
        },
        mounted() {
            this.toggleStickyHeader();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.toggleStickyHeader);
        }
    }
</script>