<template>
    <div>
        <div class="row mt_dec--10 row--15" v-if="type!='PC'">
            <div :class="column"
                 v-for="(gallery, index) in galleryData"
                 :key="index">
                <div class="rn-gallery icon-center" data-aos="fade-up">
                    <div class="thumbnail">
                        <img
                            v-if="gallery.thumb"
                            :src="gallery.thumb"
                            alt="Video Thumbnail"/>
                    </div>
                    <!-- <div class="video-icon">
                        <Button variant-button-class="rounded-player sm-size"
                                :is-link="false"
                                @btnClick="imageIndex = index">
                            <span><Icon :name="gallery.type === 'video' ? 'play' : 'zoom-in'" size="30"/></span>
                        </Button>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="row mt_dec--10 row--15" v-if="type=='PC'">
            <div :class="column"
                 v-for="(gallery, index) in galleryData"
                 :key="index">
                <div class="rn-gallery icon-center" data-aos="fade-up">
                    <div class="thumbnail">
                        <img
                            style="width:36%;margin:0 32%"
                            v-if="gallery.thumb"
                            :src="gallery.thumb"
                            alt="Video Thumbnail"/>
                    </div>
                    <!-- <div class="video-icon">
                        <Button variant-button-class="rounded-player sm-size"
                                :is-link="false"
                                @btnClick="imageIndex = index">
                            <span><Icon :name="gallery.type === 'video' ? 'play' : 'zoom-in'" size="30"/></span>
                        </Button>
                    </div> -->
                </div>
            </div>
        </div>

        <CoolLightBox
            :items="galleryData"
            :index="imageIndex"
            @close="imageIndex = null">
        </CoolLightBox>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'
    import Button from '../button/Button'

    export default {
        name: 'Gallery',
        components: {Button, Icon},
        props: {
            galleryData: {},
            column: {
                type: String,
                default: 'col-lg-12 col-md-6 col-12'
            }
        },
        data() {
            return {
                imageIndex: null,
                type: 'PC',
            }
        },
        methods:{
            _isMobile() {
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                return flag;
            }
        },
        mounted(){
            if (this._isMobile()) {
                this.type = 'phone'
            } else {
            }
            console.log(this.type)
        }
    }
</script>